import { useQuery} from "@apollo/client";
import { getPageBySearch } from "../../queries/queries.js";

import CategoryList from '../CategoryList';
import PageHeader from '../PageHeader';
import PageMainImage from '../PageMainImage';
import PageParagraph from '../PageParagraph';
import PageTitle from '../PageTitle';
import LoaderPage from '../LoaderPage';
import ErrorPage from '../ErrorPage';
import Navbar from '../Navbar';
import Footer from'../Footer';

function Work({setCurrentCategoryID,setCurrentCategoryName,setCurrentCategorySlug}) {
  const { loading, error, data } = useQuery(getPageBySearch('Work'));

  if (loading) return <LoaderPage currentPage='Work' setCurrentCategoryName={setCurrentCategoryName}  />;
  if (error) return <ErrorPage currentPage='Work' setCurrentCategoryName={setCurrentCategoryName}  />;

  let page=null;
  if(data!==undefined) {if(data.allPages[0]!==undefined) page=data.allPages[0];}
  return (
      <div className="work-page">
        <Navbar currentPage='Work' setCurrentCategoryName={setCurrentCategoryName} />
        <PageTitle currentPage='Work' setCurrentCategoryName={setCurrentCategoryName} svgSimple={true}/>
        <PageHeader page={page} />
        <CategoryList  displayImage={true} setCurrentCategoryID={setCurrentCategoryID} setCurrentCategoryName={setCurrentCategoryName} setCurrentCategorySlug={setCurrentCategorySlug} page={page} />
        <PageMainImage page={page}/>
        <PageParagraph page={page}/>
        <Footer />
      </div>
  );
}

export default Work;
