import { Container,Grid,Box } from "@material-ui/core";
import parse from 'html-react-parser';
import {  useTheme } from '@material-ui/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

function PostContent({post}) {
  const theme = useTheme();
  //const isMobile = useMediaQuery(theme.breakpoints.down('xs'), { defaultMatches: true });
  const isTablet = useMediaQuery(theme.breakpoints.down('sm'), { defaultMatches: true });
  let postContent,postExtra='';
  if(post.more!==null && post.description!==null){
    const handleExtraClick = (e) => {
      e.preventDefault();
      const extraText=e.target.parentNode.parentNode.querySelector('.post-content-extra-text');
      console.log(extraText.className);
      if(extraText.className.indexOf("open")>-1){
        e.target.text="More...";
        extraText.classList.remove("open");
      }else{
        e.target.text="Less...";
        extraText.classList.add("open");
      }
    }
    if(post.extra!==null) postExtra=<div className="post-content-extra">
                                      <div className="post-content-extra-text">{parse(post.extra)}</div>
                                      <div className="post-content-extra-link"><a href="/" onClick={(e) => handleExtraClick(e)}>More...</a></div>
                                    </div>;
    postContent=<Grid container>
                  <Grid item xs={12} md={8} className="post-content-left">
                    <Box pl={isTablet?1:0}>{parse(post.description)}</Box>
                  </Grid>
                  <Grid item xs={12} md={4} className="post-content-right">
                    <Box pl={isTablet?1:2} mt={isTablet?2:0}>
                    {parse(post.more)}
                    {postExtra}
                    </Box>
                  </Grid>
                </Grid>;
  }else if(post.more===null && post.description!==null){
    postContent=parse(post.description);
  }else{
    postContent='';
  }
  return (
    <section className="post-content">
      <Container disableGutters={true} className="very-light-grey-container post-content-wrapper"  maxWidth={false}>
        <Container disableGutters={true} fixed className="post-content-container">
          {postContent}
        </Container>
      </Container>
    </section>
  );
}

export default PostContent;
