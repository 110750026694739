function SVGLine() {
  return (
    <div className='page-title-spacer mobile-hide'>
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 3 25">
        <rect className="logo_highlight" x="0" y="0" width="3" height="25"/>
      </svg>
    </div>
  );
}

export default SVGLine;
