import Player from '@vimeo/player';

function getVideoURL(videoURL){
  let srcURL;
	if(videoURL.indexOf("vimeo")>-1){ //"https://player.vimeo.com/video/"+videoURL.split(".com/")[1]+"?title=0&amp;byline=0&amp;portrait=0&amp;color=000000&amp;muted=1";
    srcURL="https://player.vimeo.com/video/"+videoURL.split(".com/")[1]+"?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;muted=1&amp;controls=1";
		return srcURL;
    //Example working URL: "https://player.vimeo.com/video/118583343?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" width="1920" height="1080" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen title="Leap Motion Hack 02 - DirectX 11"></iframe>
		/* if(quality!="auto"){result+="&quality="+b;} */ // this is for forcing quality of video
	}else{
    srcURL="https://www.youtube.com/embed/"+videoURL.split("v=")[1]+"?mute=1&autoplay=1&enablejsapi=1";
		//return "https://www.youtube.com/embed/"+_.split(a,"v=")[1]+"?showinfo=0&autoplay=1&enablejsapi=1";
	}
  return srcURL;
}
function startVideo(videoURL){
  if(videoURL.indexOf("vimeo")>-1){
    setTimeout(function(){
      const iframe=document.querySelector(".post-video-player");
      if(iframe!==null){
        var vplayer = new Player(iframe);
        vplayer.setVolume(0);
        vplayer.play();
      }
    },50);
  }else{
    //No need to start youtube  
  }
}


export {startVideo,getVideoURL};
