import { Container,Box, Button } from "@material-ui/core";
//import Player from '@vimeo/player';
import {startVideo,getVideoURL} from '../scripts/VideoTools'

function PostHeader({post,postName}) {
  let mainImage,mainImageContainer,postHeader,imagesURL,imageCropGravity='center',postTitle='',portraitClass='';
  if(post.mainImageCrop!==null) imageCropGravity=post.mainImageCrop.toLowerCase();

  if(post.title!==null) postTitle=<div className="post-title"><h3>{post.title}</h3></div>;
  if(post.mainImage!==null){  //POST HAS A MAIN IMAGE!

    const urlArr=post.mainImage.publicUrl.split("upload");//const urlArr=["https://res.cloudinary.com/eeeps/image/","/on_the_phone.jpg"]

    if(post.video!==null){ // POST HAS A VIDEO!

      if(post.videoAspectRatio==="Portrait") {  //VIDEO IS PORTRAIT!
        portraitClass=' portrait';
        imagesURL=urlArr[0]+"upload/f_auto,q_70,w_600,h_230,c_fill,g_"+imageCropGravity+urlArr[1]+" 600w,";
        imagesURL+=urlArr[0]+"upload/f_auto,q_70,w_414,h_242,c_fill,g_"+imageCropGravity+urlArr[1]+" 414w,";
        imagesURL+=urlArr[0]+"upload/f_auto,q_70,w_728,h_360,c_fill,g_"+imageCropGravity+urlArr[1]+" 728w,";
        imagesURL+=urlArr[0]+"upload/f_auto,q_70,w_1048,h_333,c_fill,g_"+imageCropGravity+urlArr[1]+" 1048w";
        mainImage=<img  srcSet={imagesURL}
                        sizes="(max-width: 599px) 100vw,(max-width: 959px) 414px,(max-width: 1279px) 728px,(min-width: 1280px) 1048px"
                        src={urlArr[0]+"upload/f_auto,q_70,w_1048,h_333,c_fill,g_"+imageCropGravity+urlArr[1]}
                        alt={postName+" main image"} />;
      } else{
        imagesURL=urlArr[0]+"upload/f_auto,q_70,w_600,h_230,c_fill,g_"+imageCropGravity+urlArr[1]+" 600w,";
        imagesURL+=urlArr[0]+"upload/f_auto,q_70,w_283,h_214,c_fill,g_"+imageCropGravity+urlArr[1]+" 283w,";
        imagesURL+=urlArr[0]+"upload/f_auto,q_70,w_560,h_214,c_fill,g_"+imageCropGravity+urlArr[1]+" 560w,";
        imagesURL+=urlArr[0]+"upload/f_auto,q_70,w_880,h_240,c_fill,g_"+imageCropGravity+urlArr[1]+" 880w";
        mainImage=<img  srcSet={imagesURL}
                        sizes="(max-width: 599px) 100vw,(max-width: 959px) 283px,(max-width: 1279px) 560px,(min-width: 1280px) 880px"
                        src={urlArr[0]+"upload/f_auto,q_70,w_880,h_240,c_fill,g_"+imageCropGravity+urlArr[1]}
                        alt={postName+" main image"} />;
      }

      mainImageContainer=<div className="post-main-image">{mainImage}</div>

      if(post.urlLink!==null) mainImageContainer=<div className="post-main-image">{mainImage}<div className="post-main-image-overlay"><a href={post.urlLink} target="_blank" rel="noreferrer"><Button variant="contained" color="primary"><span className="link-button">Visit Website</span></Button></a></div></div>


      postHeader=<Box className={'post-header-full'+portraitClass}>
                    <Box className={'post-header-left'+portraitClass}>
                      <iframe src={getVideoURL(post.video)} onLoad={startVideo(post.video)} className="post-video-player" width="100%" height="100%" title={postName+' main video'} frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
                    </Box>
                    <Box className="post-header-right">
                      {postTitle}
                      {mainImageContainer}
                    </Box>
                  </Box>;
    }else{ //POST HAS NO VIDEO!
      imagesURL=urlArr[0]+"upload/f_auto,q_70,w_600,h_230,c_fill,g_"+imageCropGravity+urlArr[1]+" 600w,";
      imagesURL+=urlArr[0]+"upload/f_auto,q_70,w_960,h_150,c_fill,g_"+imageCropGravity+urlArr[1]+" 960w,";
      imagesURL+=urlArr[0]+"upload/f_auto,q_70,w_1280,h_150,c_fill,g_"+imageCropGravity+urlArr[1]+" 1280w"; //console.log(imageURL);
      mainImage=<img  srcSet={imagesURL}
                      sizes="(max-width: 599px) 100vw,(max-width: 959px) 600px,(max-width: 1279px) 960px,(min-width: 1280px) 1280px"
                      src={urlArr[0]+"upload/f_auto,q_70,w_1280,h_150,c_fill,g_"+imageCropGravity+urlArr[1]}
                      alt={postName+" main image"} />
      mainImageContainer=<div className="post-main-image">{mainImage}</div>
      if(post.urlLink!==null) mainImageContainer=<div className="post-main-image">{mainImage}<div className="post-main-image-overlay"><a href={post.urlLink} target="_blank" rel="noreferrer"><Button variant="contained" color="primary"><span className="link-button">Visit Website</span></Button></a></div></div>
      postHeader=<div className="post-header-no-video">
                    {postTitle}
                    {mainImageContainer}
                  </div>;
    }
  }else{ //POST HAS NO MAIN IMAGE!
    if(post.video!==null){ //POST HAS NO MAIN IMAGE BUT HAS A VIDEO
      postHeader=<Box className="post-header-full">
                    <Box className="post-header-left">
                      <iframe src={getVideoURL(post.video)} onLoad={startVideo(post.video)} className="post-video-player" width="100%" height="100%" title={postName+' main video'} frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
                    </Box>
                    <Box className="post-header-right">
                      {postTitle}
                    </Box>
                  </Box>;
    }else{ //POST HAS NO MAIN IMAGE AND HAD NO VIDEO EITHER
      postHeader=<div className="post-header-no-video">{postTitle}</div>;
      if(post.title===null) postHeader='';
    }
  }
  return (
    <section className="post-header">
      <Container disableGutters={true} className="light-grey-container page-header-wrapper"  maxWidth={false}>
        <Container fixed className="post-header-container"  disableGutters={true}>
          {postHeader}
        </Container>
      </Container>
    </section>
  );
}

export default PostHeader;
