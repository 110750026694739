import { Grid, Container } from "@material-ui/core";
import { Link } from 'react-router-dom';
import CardContainer from './CardContainer';
import CardContainerNoImage from './CardContainerNoImage';

function CategoryList({setCurrentCategoryID,setCurrentCategoryName,setCurrentCategorySlug,page,displayImage}) {
  const handleClick = (id,name,slug) => { setCurrentCategoryID(id);setCurrentCategoryName(name);setCurrentCategorySlug(slug); }
  let showImage=false; if(displayImage!==null) showImage=displayImage;
  return (
    <Container disableGutters={true} className="dark-grey-container grid-list-wrapper"  maxWidth={false}>
      <Container disableGutters={true} fixed>
      <Grid container>
      {
        page.categoriesOrder.map(category => {
        let image='',imagesURL,imageCropGravity='center';
        if(category.posterPost!=null){
          const urlArr=category.posterPost.mainImage.publicUrl.split("upload");
          if(category.posterPost.mainImageCrop!==null) imageCropGravity=category.posterPost.mainImageCrop.toLowerCase();
          imagesURL=urlArr[0]+"upload/f_auto,q_70,w_568,h_254,c_fill,g_"+imageCropGravity+urlArr[1]+" 568w,";
          imagesURL+=urlArr[0]+"upload/f_auto,q_70,w_268,h_150,c_fill,g_"+imageCropGravity+urlArr[1]+" 268w,";
          imagesURL+=urlArr[0]+"upload/f_auto,q_70,w_208,h_117,c_fill,g_"+imageCropGravity+urlArr[1]+" 208w,";
          imagesURL+=urlArr[0]+"upload/f_auto,q_70,w_288,h_162,c_fill,g_"+imageCropGravity+urlArr[1]+" 288w";
          image=<img  srcSet={imagesURL}
                      sizes="(max-width: 599px) calc(100vw - 32px),(max-width: 959px) 268px,(max-width: 1279px) 208px,(min-width: 1280px) 288px"
                      src={urlArr[0]+"upload/f_auto,q_70,w_288,h_162,c_fill,g_"+imageCropGravity+urlArr[1]}
                      alt={category.name+" poster image"} />
        }
        let cardContainer=<Grid item xs={12} sm={6} md={3} key={category.id}>
                            <Link className="card-anchor" onClick={() => handleClick(category.id,category.name,category.slug)} to={'/work/'+category.slug}>
                              <CardContainer name={category.name} image={image}/>
                            </Link>
                          </Grid>;
        if(!showImage){
          cardContainer=<Grid item xs={12} md={6} key={category.id}>
            <Link className="card-anchor" onClick={() => handleClick(category.id,category.name,category.slug)} to={'/work/'+category.slug}>
              <CardContainerNoImage name={category.name} />
            </Link>
          </Grid>;
        }


        return (cardContainer)
        })
      }
      </Grid>
      </Container>
    </Container>
  );
}

export default CategoryList;
