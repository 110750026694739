import { useParams } from 'react-router-dom';
import { useQuery} from "@apollo/client";
import { getPosts, getPostsBySlug, getCategoryTopPostsByID, getCategoryTopPostsBySlug } from "../../queries/queries.js";

import PostList from '../PostList';
import PageTitle from '../PageTitle';
import LoaderPage from '../LoaderPage';
import ErrorPage from '../ErrorPage';
import Navbar from '../Navbar';
import Footer from '../Footer';

function unslug(slug){
  slug=slug.replace(/-/g," ").replace(/and/g,"&").replace("vj","VJ").replace("vfx","VFX").replace("vr & ar","VR & AR");
  return slug.replace(/^\w/, (c) => c.toUpperCase());
}

function Category({currentCategoryID,setCurrentPostID,currentCategoryName,setCurrentCategoryName,setCurrentPostName,currentCategorySlug,setCurrentCategorySlug}) {
  const { categorySlug } = useParams();
  let postsQuery,topPostsQuery,categoryName;
  if(currentCategoryName!=="nada"){
    categoryName=currentCategoryName;
  } else {
    categoryName=unslug(categorySlug);
  }
  if(currentCategoryID!=="nada"){
    postsQuery=getPosts(currentCategoryID);
    topPostsQuery=getCategoryTopPostsByID(currentCategoryID);
  } else{
    postsQuery=getPostsBySlug(categorySlug);
    topPostsQuery=getCategoryTopPostsBySlug(categorySlug);
  }

  const { data:postsResults, error:errorPosts, loading:loadingPosts } = useQuery(postsQuery);

  const { data:topPostsResults, error:errorTopPosts, loading:loadingTopPosts } = useQuery(topPostsQuery);

  if (loadingPosts || loadingTopPosts) return <LoaderPage currentPage='Work' setCurrentCategoryName={setCurrentCategoryName}  />;
  if (errorPosts || errorTopPosts) return <ErrorPage currentPage='Work' setCurrentCategoryName={setCurrentCategoryName}  />;

  let wrangledPosts;
  const postOrders=topPostsResults.allPostOrders;

  if(postOrders!==undefined){
    //let topPosts=[...category.topPosts];
    let topPosts=[];
    for( var k=0;k<postOrders.length;k++){
      topPosts.push(postOrders[k].post);
    }

    const posts=postsResults.allPosts;

    let notTopPosts=[];
    for(var i=0;i<posts.length;i++){
      let found=false;
      for(var j=0;j<topPosts.length;j++){
        if(posts[i].id===topPosts[j].id) {
          found=true;
          break;
        }
      }
      if(!found) notTopPosts.push(posts[i]);
    }
    wrangledPosts=topPosts.concat(notTopPosts);
  }else{
    wrangledPosts=postsResults.allPosts;
  }
  return (
      <div className="category-page">
        <Navbar currentPage='Work' setCurrentCategoryName={setCurrentCategoryName} />
        <PageTitle currentPage='Work' setCurrentCategoryName={setCurrentCategoryName} currentCategoryName={categoryName} currentCategorySlug={categorySlug} setCurrentCategorySlug={setCurrentCategorySlug}/>
        <PostList setCurrentPostID={setCurrentPostID} data={wrangledPosts} setCurrentPostName={setCurrentPostName} />
        <Footer />
      </div>
  );
}

export default Category;
