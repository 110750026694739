import { useQuery} from "@apollo/client";
import { getPageBySearch } from "../../queries/queries.js";
import { startVideo, getVideoURL} from '../../scripts/VideoTools'
import { Grid,Container } from "@material-ui/core";
import parse from 'html-react-parser';
import CategoryList from '../CategoryList';
import PageHeader from '../PageHeader';
import PageMainImage from '../PageMainImage';
import PageTitle from '../PageTitle';
import LoaderPage from '../LoaderPage';
import ErrorPage from '../ErrorPage';
import Navbar from '../Navbar';
import Footer from'../Footer';

function Home({setCurrentCategoryID,setCurrentCategoryName,setCurrentCategorySlug}) {
  const { loading, error, data } = useQuery(getPageBySearch('Home'));

  if (loading) return <LoaderPage currentPage='Home' setCurrentCategoryName={setCurrentCategoryName}  />;
  if (error) return <ErrorPage currentPage='Home' setCurrentCategoryName={setCurrentCategoryName}  />;

  let page=null,homeBody='ERROR: No data for this page.';
  if(data!==undefined) {if(data.allPages[0]!==undefined) page=data.allPages[0];}
  if(page!==null){
    if(page.video!==null){ // HOME HAS A VIDEO!

      homeBody=<Grid container className="home-body">
                  <Grid item xs={12} md={7}>
                    <div className="home-body-video">
                      <iframe src={getVideoURL(page.video)} onLoad={startVideo(page.video)} className="post-video-player" width="100%" height="100%" title={page.name+' main video'} frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <div className="home-body-content">
                      <div className="home-body-text">
                        <h2>{page.contentHeader}</h2>
                        {parse(page.contentText)}
                      </div>
                      <CategoryList displayImage={false} setCurrentCategoryID={setCurrentCategoryID} setCurrentCategoryName={setCurrentCategoryName} setCurrentCategorySlug={setCurrentCategorySlug} page={page} />
                    </div>
                  </Grid>
              </Grid>;
    }else{
      homeBody=<Grid container className="home-body">
                  <div className="home-body-text">
                    <h2>{page.contentHeader}</h2>
                    {parse(page.contentText)}
                  </div>
                  <CategoryList displayImage={false} setCurrentCategoryID={setCurrentCategoryID} setCurrentCategoryName={setCurrentCategoryName} setCurrentCategorySlug={setCurrentCategorySlug} page={page} />
               </Grid>;
    }
  }

  return (
      <div className="home-page">
        <Navbar currentPage='Home' setCurrentCategoryName={setCurrentCategoryName} />
        <PageTitle currentPage='Home' setCurrentCategoryName={setCurrentCategoryName} svgSimple={true} />
        <PageHeader page={page} />
        <Container disableGutters={true} className="dark-grey-container body-container"  maxWidth={false}>
          <Container disableGutters={true} fixed>
          { homeBody }
          </Container>
        </Container>
        <PageMainImage page={page}/>
        <Footer />
      </div>
  );
}

export default Home;
