import { Container } from "@material-ui/core";

function Error() {

  return (
    <Container disableGutters={true} maxWidth={false}>
      <Container fixed>
      Error :(
      </Container>
    </Container>
  );
}

export default Error;
