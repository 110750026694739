//import { Link } from 'react-router-dom';
import { Container } from "@material-ui/core";

function PageMainImage({page}) {
  if(page===null) return '';
  let mainImage;
  if(page.mainImage!==null){
    const urlArr=page.mainImage.publicUrl.split("upload");
    let imagesURL=urlArr[0]+"upload/f_auto,q_70,w_600,h_275,c_fill,g_center"+urlArr[1]+" 600w,";
    imagesURL+=urlArr[0]+"upload/f_auto,q_70,w_960,h_225,c_fill,g_center"+urlArr[1]+" 960w,";
    imagesURL+=urlArr[0]+"upload/f_auto,q_70,w_1280,h_225,c_fill,g_center"+urlArr[1]+" 1280w";

    mainImage=<img srcSet={imagesURL} sizes="(max-width: 599px) 100vw,(max-width: 959px) 100vw,(max-width: 1279px) 100vw,(min-width: 1280px) 100vw"
                     src={urlArr[0]+"upload/f_auto,q_70,w_1280,h_225,c_fill,g_center"+urlArr[1]} alt={page.name+" main image"} />
  }else{
    return '';
  }
  return (
    <section>
      <Container disableGutters={true} maxWidth={false}>
            <div className="home-main-image-container">
              { mainImage }
            </div>
      </Container>
    </section>
  );
}

export default PageMainImage;
