//import {Image, Transformation, CloudinaryContext} from 'cloudinary-react';
//import { Link } from 'react-router-dom';
//import { useEffect } from 'react';
//import useScript from '../../hooks/useScript';
import { useQuery} from "@apollo/client";
import { getPost, getPostBySlug } from "../../queries/queries.js";
import { useParams } from 'react-router-dom';
import Loader from '../Loader';
import Error from '../Error';
import PageTitle from '../PageTitle';
import Navbar from '../Navbar';
import PostContent from '../PostContent';
import PostHeader from'../PostHeader';
import PostImages from'../PostImages';
import Footer from'../Footer';

function Post({currentPostID,currentCategoryName,setCurrentCategoryName,currentPostName,currentCategorySlug,setCurrentCategorySlug}) {
  /*useEffect(() => {
    setTimeout(function(){
      console.log("dom painted, starting vimeo");
      const iframe=document.querySelector(".post-video-player");
      var vplayer = new Player(iframe);
      vplayer.setVolume(0);
      vplayer.play();
    },50);
  },[]);*/
//  useScript("../../../node_modules/cloudinary-core/cloudinary-core-shrinkwrap.min.js");

  const { postSlug } = useParams();
  let post,query,usingID=true,categoryName,postName,categorySlug;
  if(currentPostID!=="nada") {
    query=getPost(currentPostID);
  }else{
    query=getPostBySlug(postSlug);
    usingID=false;
  }
  const { loading, error, data } = useQuery(query);
  if (loading) return <Loader />;
  if (error) return <Error />;

  if(usingID) {
    post=data.Post;postName=currentPostName;
  } else {
    post=data.allPosts[0];postName = post.name;
  }
  categoryName = currentCategoryName;
  if(currentCategoryName==='nada') categoryName = post.categories[0].name;

  categorySlug = currentCategorySlug;
  if(currentCategorySlug==="nada") categorySlug=post.categories[0].slug;

  return (
    <div className="post-page">
      <Navbar currentPage='Work' setCurrentCategoryName={setCurrentCategoryName} />
      <PageTitle currentPage='Work' setCurrentCategoryName={setCurrentCategoryName} currentCategoryName={categoryName} currentPostName={postName} currentCategorySlug={categorySlug} setCurrentCategorySlug={setCurrentCategorySlug} />
      <PostHeader post={post} postName={postName} />
      <PostContent post={post} />
      <PostImages post={post} />
      <Footer />
    </div>
  );
}

export default Post;
