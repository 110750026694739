import { Link } from 'react-router-dom';
import SVGLogo from './SVGLogo';
import { Container, Box, Button } from "@material-ui/core";
import MenuIcon from '@material-ui/icons/Menu';

function Navbar({setCurrentCategoryName,currentPage}) {
  const handleClick = (e) => {
    const currentTab=document.querySelector(".nav-link.selected")
    if(currentTab!==null) currentTab.classList.remove("selected");
    e.target.classList.add("selected");
    setCurrentCategoryName('nada');
  }

  const handleBurgerClick = (e) => {
    const mobileMenu=document.querySelector(".mobile-menu-container");
    if (mobileMenu.className.indexOf("open")>-1) {
      mobileMenu.classList.remove("open");
    }else{
      mobileMenu.classList.add("open");
    }
  }
  return (
    <header>
      <Container disableGutters={true} maxWidth={false}>
      <nav>
        <div className="nav-wrapper">
          <Container disableGutters={true} fixed>
            <div className="nav-container">
              <div className="nav-bar">
                <Link to="/" className="nav-logo-link">
                  <Box className="nav-logo" mr={1}><div className="nav-logo-rotate"><SVGLogo/></div></Box>
                  <Box className="nav-brand" mr={2}><span className="nav-brand-not-bold">Euphoric </span><span className="nav-brand-bold">Blast</span></Box>
                </Link>
                <ul className="nav-links mobile-hide-flex">
                  <li><Link to="/" onClick={(e) => handleClick(e)} className={"nav-link "+(currentPage==='Home'?'selected':'')}><Box className="nav-link-name" pr={2} pl={2}>Home</Box></Link></li>
                  <li><Link to="/work" onClick={(e) => handleClick(e)} className={"nav-link "+(currentPage==='Work'?'selected':'')}><Box className="nav-link-name" pr={2} pl={2}>Work</Box></Link></li>
                  <li><Link to="/lab" onClick={(e) => handleClick(e)} className={"nav-link "+(currentPage==='Lab'?'selected':'')}><Box className="nav-link-name" pr={2} pl={2}>Lab</Box></Link></li>
                  <li><a href="/vr" className="nav-link-vr"><Button variant="contained" color="primary" className="nav-button">View in 3D</Button></a></li>
                </ul>
                <div className="burger-container mobile-show-flex"><Button variant="contained" color="primary" startIcon={<MenuIcon />} onClick={(e) => handleBurgerClick(e)}>Menu</Button></div>
              </div>
            </div>
          </Container>
        </div>
        <div className="mobile-menu-container mobile-show-flex">
          <Container disableGutters={true} fixed>
                  <ul className="nav-links">
                    <li><a href="/vr" className="nav-link-vr nav-link-vr-mobile"><Button fullWidth={true} variant="contained" color="primary" className="nav-button">View website in AR/VR</Button></a></li>
                    <li><Link to="/" onClick={(e) => handleClick(e)} className={"nav-link "+(currentPage==='Home'?'selected':'')}><Box className="nav-link-name" pr={2} pl={2}>Home</Box></Link></li>
                    <li><Link to="/work" onClick={(e) => handleClick(e)} className={"nav-link "+(currentPage==='Work'?'selected':'')}><Box className="nav-link-name" pr={2} pl={2}>Work</Box></Link></li>
                    <li><Link to="/lab" onClick={(e) => handleClick(e)} className={"nav-link "+(currentPage==='Lab'?'selected':'')}><Box className="nav-link-name" pr={2} pl={2}>Lab</Box></Link></li>
                  </ul>
          </Container>
        </div>
      </nav>
      </Container>
    </header>
  );
}

export default Navbar;
