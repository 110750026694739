import { Container } from "@material-ui/core";

function Footer() {
  const year = new Date().getFullYear();
  return (
    <footer>
    <Container disableGutters={true} maxWidth={false} className="footer-wrapper">
        <Container fixed>
          <div className="footer">
          This is NOT a blog site. Hand made in Node.js, React & GraphQL.<br/>© Euphoric Blast {year}
          </div>
        </Container>
    </Container>
    </footer>
  );
}

export default Footer;
