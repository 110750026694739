function SVGLineDouble({line}) {
  let lineClass='';
  if(line!==undefined) lineClass=line;
  return (
    <div className='page-title-spacer mobile-show'>
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 25 29">
        <rect className="logo_highlight" x="0" y="16" width="20" height="3"></rect>
        <rect className={'logo_highlight mobile-show vertical-line '+lineClass} x="0" y="0" width="3" height="29"></rect>
      </svg>
    </div>
  );
}

export default SVGLineDouble;
