function SVGDropCap({simple}) {
  let extraRect='';
  let mobileHide='';
  if(!simple) {
    extraRect=<rect className="logo_highlight mobile-show-inline" x="0.0" y="220.0" width="250.0" height="30.0"/>;
    mobileHide=" mobile-hide";
  }
  return (
    <div className="drop-cap-container">
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 250 250">
        <path className="drop-cap-back" d="M237.486,250H12.514c-6.83,0-12.367-5.537-12.367-12.367V12.701c0-6.83,5.537-12.367,12.367-12.367h224.855c6.828,0,12.364,5.533,12.367,12.361l0,224.979C250,244.506,244.319,250,237.486,250z"/>
    		<rect className={'logo_highlight'+mobileHide} x="220" y="0" width="30" height="300"/>
        {extraRect}
      </svg>
    </div>
  );
}

export default SVGDropCap;
