import { Grid, Container } from "@material-ui/core";
import { Link } from 'react-router-dom';
import CardContainer from './CardContainer';

function PostList({setCurrentPostID,data,setCurrentPostName}) {
  const handleClick = (id,name) => { setCurrentPostID(id);setCurrentPostName(name); }
  let postsLaidOut,postsArr=[];

  for(var j=0;j<data.length;j++) postsArr.push({post:data[j]});

  const postsAmount=postsArr.length;
  if(postsAmount>4){
    const foo=Math.floor(postsAmount/5);
    const amountLeft=postsAmount-foo*5;
    for(var i=0;i<postsAmount;i++){
      if(i<foo*5){
        if(i%5<3)postsArr[i].grid=4
        else postsArr[i].grid=6;
      }else{
        if(amountLeft===2){
          postsArr[i].grid=6;
        }else if(amountLeft===4){
          if(i%5<3)postsArr[i].grid=4
          else postsArr[i].grid=12;
        }else if(amountLeft===3){
          postsArr[i].grid=4;
        }else if(amountLeft===1){
          postsArr[i].grid=12;
        }
      }
    }
  }else if(postsAmount===4 || postsAmount===2){
    for(var k=0;k<postsAmount;k++) postsArr[k].grid=6;
  }else if(postsAmount===3){
    for(var l=0;l<postsAmount;l++) postsArr[l].grid=4;
  }else if(postsAmount===1){
    postsArr[0].grid=12;
  }

  if(postsAmount>0){
    postsLaidOut=<Container disableGutters={true} className="dark-grey-container grid-list-wrapper" maxWidth={false}>
                  <Container  disableGutters={true} fixed>
                    <Grid container>
                      {
                        postsArr.map((data,index) => {
                        let imagesURL,image,imageCropGravity='center';
                        if(data.post.mainImageCrop!==null) imageCropGravity=data.post.mainImageCrop.toLowerCase();

                        const urlArr=data.post.mainImage.publicUrl.split("upload");
                        //const urlArr=["https://res.cloudinary.com/eeeps/image/","/on_the_phone.jpg"]; //Test image

                        if(data.grid===4){
                              imagesURL=urlArr[0]+"upload/f_auto,q_70,w_568,h_254,c_fill,g_"+imageCropGravity+urlArr[1]+" 568w,";
                              imagesURL+=urlArr[0]+"upload/f_auto,q_70,w_268,h_150,c_fill,g_"+imageCropGravity+urlArr[1]+" 268w,";
                              imagesURL+=urlArr[0]+"upload/f_auto,q_70,w_288,h_162,c_fill,g_"+imageCropGravity+urlArr[1]+" 288w,";
                              imagesURL+=urlArr[0]+"upload/f_auto,q_70,w_395,h_223,c_fill,g_"+imageCropGravity+urlArr[1]+" 395w";
                              image=<img  srcSet={imagesURL}
                                          sizes="(max-width: 599px) calc(100vw - 32px),(max-width: 959px) 268px,(max-width: 1279px) 288px,(min-width: 1280px) 395px"
                                          src={urlArr[0]+"upload/f_auto,q_70,w_395,h_223,c_fill,g_"+imageCropGravity+urlArr[1]}
                                          alt={data.post.name+" image "+index} />
                        }else if(data.grid===6){
                              imagesURL=urlArr[0]+"upload/f_auto,q_70,w_568,h_254,c_fill,g_"+imageCropGravity+urlArr[1]+" 568w,";
                              imagesURL+=urlArr[0]+"upload/f_auto,q_70,w_268,h_150,c_fill,g_"+imageCropGravity+urlArr[1]+" 268w,";
                              imagesURL+=urlArr[0]+"upload/f_auto,q_70,w_448,h_252,c_fill,g_"+imageCropGravity+urlArr[1]+" 448w,";
                              imagesURL+=urlArr[0]+"upload/f_auto,q_70,w_608,h_342,c_fill,g_"+imageCropGravity+urlArr[1]+" 608w";
                              image=<img  srcSet={imagesURL}
                                          sizes="(max-width: 599px) calc(100vw - 32px),(max-width: 959px) 268px,(max-width: 1279px) 448px,(min-width: 1280px) 608px"
                                          src={urlArr[0]+"upload/f_auto,q_70,w_608,h_342,c_fill,g_"+imageCropGravity+urlArr[1]}
                                          alt={data.post.name+" image "+index} />
                        }else if(data.grid===12){
                              imagesURL=urlArr[0]+"upload/f_auto,q_70,w_568,h_254,c_fill,g_"+imageCropGravity+urlArr[1]+" 568w,";
                              imagesURL+=urlArr[0]+"upload/f_auto,q_70,w_268,h_150,c_fill,g_"+imageCropGravity+urlArr[1]+" 268w,";
                              imagesURL+=urlArr[0]+"upload/f_auto,q_70,w_928,h_252,c_fill,g_"+imageCropGravity+urlArr[1]+" 928w,";
                              imagesURL+=urlArr[0]+"upload/f_auto,q_70,w_1248,h_342,c_fill,g_"+imageCropGravity+urlArr[1]+" 1248w";
                              image=<img  srcSet={imagesURL}
                                          sizes="(max-width: 599px) calc(100vw - 32px),(max-width: 959px) 268px,(max-width: 1279px) 928px,(min-width: 1280px) 1248px"
                                          src={urlArr[0]+"upload/f_auto,q_70,w_1248,h_342,c_fill,g_"+imageCropGravity+urlArr[1]}
                                          alt={data.post.name+" image "+index} />
                        }

                        return (
                          <Grid item xs={12} sm={6} md={data.grid} key={index}>
                            <Link className="card-anchor" onClick={() => handleClick(data.post.id,data.post.name)} to={'/w/'+data.post.slug }>
                              <CardContainer name={data.post.name} image={ image} />
                            </Link>
                          </Grid>
                        )
                        })
                      }
                    </Grid>
                  </Container>
                </Container>
  }else{
    return '';
  }

  return (
    <section className="post-list">
    {postsLaidOut}
    </section>);
  }
export default PostList;
