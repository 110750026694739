import { Container } from "@material-ui/core";

import PageTitle from './PageTitle';
import Navbar from './Navbar';

function ErrorPage({currentPage,setCurrentCategoryName}) {

  return (
    <div className="error-page">
      <Navbar currentPage={currentPage} setCurrentCategoryName={setCurrentCategoryName} />
      <PageTitle currentPage={currentPage} />
      <Container disableGutters={true}  maxWidth={false}>
        <Container fixed>
          Error :(
        </Container>
      </Container>
    </div>
  );
}

export default ErrorPage;
