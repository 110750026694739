import { Box } from "@material-ui/core";

function CardContainerNoImage({name,image}) {
  let cardName='';
  if(name!==undefined) cardName=<h3>{name}</h3>
  return (
    <Box className="card-container" p={2}>
      {cardName}
      <div className="horizontal-line"></div>
    </Box>
  );
}

export default CardContainerNoImage;
