import { Container } from "@material-ui/core";
import parse from 'html-react-parser';
import PageParagraphImages from './PageParagraphImages';
import NoDataContainer from './NoDataContainer';
function PageParagraph({page}) {
  const nodata=<NoDataContainer />;

  if(page===null || page.contentHeader===null) return nodata;
  return (
    <section className="page-paragraph">
      <Container disableGutters={true} maxWidth={false} className="light-grey-container page-header-wrapper page-paragraph-wrapper">
          <Container fixed>
            <h2>{page.contentHeader}</h2>
            <hr/>
            {parse(page.contentText)}
            <PageParagraphImages page={page}/>
          </Container>
      </Container>
    </section>
  );
}

export default PageParagraph;
