import { Container } from "@material-ui/core";

function PageHeader({currentPage,page}) {
  if(page===null) return '';
  return (
    <section className="page-header">
      <Container disableGutters={true} maxWidth={false} className="light-grey-container page-header-wrapper">
          <Container fixed>
            <h2>{page.title}</h2>
            <h3>{page.subtitle}</h3>
          </Container>
      </Container>
    </section>
  );
}

export default PageHeader;
