import { ApolloClient, InMemoryCache } from '@apollo/client';
import { ApolloProvider } from '@apollo/client/react';
import { BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import { useState } from 'react';
//Components
import Home from './components/pages/Home';
import Work from './components/pages/Work';
import Category from './components/pages/Category';
import Lab from './components/pages/Lab';
import Post from './components/pages/Post';
import About from './components/pages/About';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
//Apollo Client
const client = new ApolloClient({
  uri: "/admin/api",
  cache: new InMemoryCache()
});

function App() {
  const[currentCategoryID,setCurrentCategoryID] = useState('nada');
  const[currentCategoryName,setCurrentCategoryName] = useState('nada');
  const[currentCategorySlug,setCurrentCategorySlug] = useState('nada');
  const[currentPostID,setCurrentPostID] = useState('nada');
  const[currentPostName,setCurrentPostName] = useState('nada');
  const theme = createMuiTheme({ palette: {primary: { main: '#ffc800',}, }});

  return (
    <ApolloProvider client={client}>
      <Router>
        <div className="app">
        <ThemeProvider theme={theme}>
            <Switch>
              <Route exact path="/">
                <Home setCurrentCategoryID={setCurrentCategoryID} setCurrentCategoryName={setCurrentCategoryName} setCurrentCategorySlug={setCurrentCategorySlug} />
              </Route>
            </Switch>
            <Switch>
              <Route exact path="/work/">
                <Work setCurrentCategoryID={setCurrentCategoryID} setCurrentCategoryName={setCurrentCategoryName} setCurrentCategorySlug={setCurrentCategorySlug} />
              </Route>
            </Switch>
            <Switch>
              <Route exact path="/work/:categorySlug">
                <Category currentCategoryID={currentCategoryID} currentCategoryName={currentCategoryName} setCurrentCategoryName={setCurrentCategoryName} setCurrentPostID={setCurrentPostID}  setCurrentPostName={setCurrentPostName} setCurrentCategorySlug={setCurrentCategorySlug} currentCategorySlug={currentCategorySlug} />
              </Route>
            </Switch>
            <Switch>
              <Route exact path="/lab/">
                <Lab currentCategoryID={currentCategoryID} currentCategoryName={currentCategoryName} setCurrentCategoryName={setCurrentCategoryName} setCurrentPostID={setCurrentPostID}  setCurrentPostName={setCurrentPostName} setCurrentCategorySlug={setCurrentCategorySlug} currentCategorySlug={currentCategorySlug} />
              </Route>
            </Switch>
            <Switch>
              <Route exact path="/w/:postSlug">
                <Post currentPostID={currentPostID} currentCategoryName={currentCategoryName} setCurrentCategoryName={setCurrentCategoryName} currentPostName={currentPostName} setCurrentCategorySlug={setCurrentCategorySlug} currentCategorySlug={currentCategorySlug} />
              </Route>
            </Switch>
        </ThemeProvider>
        </div>
      </Router>
    </ApolloProvider>
  );
}

export default App;
