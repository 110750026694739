import { useQuery} from "@apollo/client";
import { getPageBySearch,getPosts,getCategoryTopPostsByID} from "../../queries/queries.js";

import PostList from '../PostList';
import PageHeader from '../PageHeader';
import PageTitle from '../PageTitle';
import NoDataContainer from '../NoDataContainer';
import LoaderPage from '../LoaderPage';
import ErrorPage from '../ErrorPage';
import Navbar from '../Navbar';
import Footer from'../Footer';

function Lab({setCurrentCategoryID,setCurrentCategoryName,setCurrentCategorySlug,setCurrentPostID,setCurrentPostName}) {
  const { data:pageResult, error:errorPage, loading:loadingPage } = useQuery(getPageBySearch('Lab'));

  let page=null;
  if(pageResult!==undefined) {if(pageResult.allPages[0]!==undefined) page=pageResult.allPages[0];}

  let lab=<NoDataContainer />;

  let postsQuery,topPostsQuery;
  const labCatID='609d2c5725f3a616408ad655';//Lab category id
  postsQuery=getPosts('609d2c5725f3a616408ad655');

  const { data:postsResults, error:errorPosts, loading:loadingPosts } = useQuery(postsQuery);

  topPostsQuery=getCategoryTopPostsByID(labCatID);
  const { data:topPostsResults, error:errorTopPosts, loading:loadingTopPosts } = useQuery(topPostsQuery);

  if (loadingPosts || loadingTopPosts || loadingPage) return <LoaderPage currentPage='Lab' setCurrentCategoryName={setCurrentCategoryName}  />;
  if (errorPosts || errorTopPosts || errorPage) return <ErrorPage currentPage='Lab' setCurrentCategoryName={setCurrentCategoryName}  />;

  let wrangledPosts;
  const postOrders=topPostsResults.allPostOrders;

  if(postOrders!==undefined){
    let topPosts=[]; //let topPosts=[...category.topPosts];
    for( var k=0;k<postOrders.length;k++){
      topPosts.push(postOrders[k].post);
    }

    const posts=postsResults.allPosts;

    let notTopPosts=[];
    for(var i=0;i<posts.length;i++){
      let found=false;
      for(var j=0;j<topPosts.length;j++){
        if(posts[i].id===topPosts[j].id) {
          found=true;
          break;
        }
      }
      if(!found) notTopPosts.push(posts[i]);
    }
    wrangledPosts=topPosts.concat(notTopPosts);
  }else{
    wrangledPosts=postsResults.allPosts;
  }
  if(page!==null) lab=<PostList setCurrentPostID={setCurrentPostID} data={wrangledPosts} setCurrentPostName={setCurrentPostName} />;
  return (
      <div className="lab-page">
        <Navbar currentPage='Lab' setCurrentCategoryName={setCurrentCategoryName} />
        <PageTitle currentPage='Lab' setCurrentCategoryName={setCurrentCategoryName} svgSimple={true} />
        <PageHeader page={page} />
        { lab }
        <Footer />
      </div>
  );
}

export default Lab;
