//import { Link } from 'react-router-dom';
import { Container } from "@material-ui/core";
import SVGDropCap from './SVGDropCap';
import SVGLine from './SVGLine';
import SVGLineDouble from './SVGLineDouble';
import { Link } from 'react-router-dom';

function PageTitle({currentPage,setCurrentCategoryName,currentCategoryName,currentPostName,currentCategorySlug,setCurrentCategorySlug,svgSimple}) {
  let simple=false;
  if(svgSimple!==undefined) simple=svgSimple;
  let category=<div className='page-title-category mobile-hide'><h2>&nbsp;</h2></div> //CSS h1 + h2 baseline shift bug fix
  let postName='',currentPageLink='',page;

  const handleCategoryClick = (e) => {
    if(setCurrentCategorySlug!==null) setCurrentCategorySlug(currentCategorySlug);
  }
  const handlePageClick = (e) => { setCurrentCategoryName('nada'); }

  if(currentCategoryName!==undefined){
    if(currentPostName!==undefined){
      category=<div className='page-title'><SVGLine /><SVGLineDouble /><Link to={'/'+currentPage.toLowerCase()+'/'+currentCategorySlug} onClick={(e) => handleCategoryClick(e)}><h2>{ currentCategoryName }</h2></Link></div>;
    }else{
      category=<div className='page-title'><SVGLine /><SVGLineDouble line={'smaller'}/><Link to={'/'+currentPage.toLowerCase()+'/'+currentCategorySlug} onClick={(e) => handleCategoryClick(e)}><h2>{ currentCategoryName }</h2></Link></div>;
    }
  }

  if(currentPostName!==undefined){
    postName=<div className='page-title page-title-project'><SVGLine /><SVGLineDouble /><h2>{ currentPostName }</h2></div>;
  }
  if(currentPage!=='Home') currentPageLink=currentPage.toLowerCase();
  page=<Link to={'/'+currentPageLink} onClick={(e) => handlePageClick(e)}><h1>{ currentPage }</h1></Link>
  return (
    <section className={simple?'page-title-wrapper':'page-title-wrapper complex'}>
      <Container disableGutters={true} maxWidth={false}>
          <Container fixed>
            <div className="page-title-container">
              <div className='page-title page-title-page'><SVGDropCap simple={simple} />{ page }</div>
              { category }
              { postName }
            </div>
          </Container>
      </Container>
    </section>
  );
}

export default PageTitle;
