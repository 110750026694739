function SVGLogo() {
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 800 800">
      <polygon  className="logo_highlight" points="496.805,428 619.806,428 558.558,366.289 "/>
      <polygon id="Eye_left" className="logo_highlight" points="180.114,428 303.115,428 241.867,366.289 "/>
      <path id="Mouth" className="logo_highlight" d="M399.268,659.722C337.86,659.722,241.614,623,241.614,574h316.691
        C558.305,623,460.676,659.722,399.268,659.722z"/>
      <rect id="Diamond" x="358.966" y="300.774" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -124.542 382.9673)" className="logo_highlight" width="82.091" height="82.091"/>
      <path id="Mouth_outter" className="logo_main" d="M77.337,463c0,165,144.083,299.281,321.818,299.281S720.973,628,720.973,463
        H77.337z M399.216,699.008C292.799,699.008,180.114,626,180.114,534h439.692C619.806,626,505.634,699.008,399.216,699.008z"/>
      <polygon id="Arrows" className="logo_main" points="708.918,245.941 709.045,246.039 668.417,205.25 700.39,172.699 722,194.446
        722,105 633.749,105 655.756,127.492 604,179.02 604,179.073 604,179.09 604,231.108 663.872,290.47 639.474,314.719
        578.917,254.064 512.81,320.242 488.01,295.535 553.049,230.342 553.113,105 584.775,105 522.013,37.766 459.229,105 491,105
        491,205.588 444.044,251.691 399.76,206.337 399.76,206.337 354.842,251.691 307,205.588 307,105 340.78,105 277.997,37.766
        215.234,105 246.896,105 246.937,230.342 311.952,295.468 287.105,320.209 220.904,254.047 160.161,314.71 135.132,290.466
        194,231.108 194,179.09 194,179.073 194,179.02 143.249,127.492 166.261,105 77,105 77,194.446 99.113,172.699 131.57,205.25
        90.948,246.039 91.072,245.941 46.665,290.338 159.929,403.603 159.985,403.695 241.838,321.867 343.858,428 387.714,428
        371.539,407.999 315.533,350.982 307.335,341.182 399.998,248.314 492.675,341.211 484.476,350.977 440.536,397.133 412.714,428
        455.688,428 557.939,321.867 639.908,403.762 640.021,403.637 753.312,290.355 "/></svg>
  );
}

export default SVGLogo;
