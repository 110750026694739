import { Container } from "@material-ui/core";

function Loader() {

  return (
    <Container disableGutters={true} maxWidth={false}>
      <Container fixed>
        <div className="loader">Loading...</div>
      </Container>
    </Container>
  );
}

export default Loader;
