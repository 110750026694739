import { Box } from "@material-ui/core";

function CardContainer({name,image}) {
  let cardName='';
  if(name!==undefined) cardName=<h2>{name}</h2>
  return (
    <Box className="card-container" p={2}>
      {cardName}
      <div className="card-content">
        <div className="card-content-image-container">{image}</div>
        <div className="side-line"></div>
      </div>
    </Box>
  );
}

export default CardContainer;
