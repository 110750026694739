import { gql } from "@apollo/client";

const getCategories=gql`
{
  allCategories(where: { name_not_starts_with:"Lab" }){
    name
    id
    slug
    posterPost{
      name
      mainImage{
        publicUrl
      }
    }
  }
}`;

const getCategoryTopPostsByID=(categoryID)=>{
  return gql`
  {
    allPostOrders(where:{ category: { id:"${categoryID}" } },sortBy: index_ASC){
      post{
        name
        slug
        id
        mainImage{
          publicUrl
        }
        mainImageCrop
      }
      category{
        id
      }
      index
    }
  }`;
}

const getCategoryTopPostsBySlug=(categorySlug)=>{
  return gql`
  {
    allPostOrders(where:{ category: { slug:"${categorySlug}" } },sortBy: index_ASC){
      post{
        name
        slug
        id
        mainImage{
          publicUrl
        }
        mainImageCrop
      }
      category{
        id
      }
      index
    }
  }`;
}

const getPageBySearch=(pageName)=>{
  return gql`
  {
    allPages(search: "${pageName}"){
      name
      slug
      title
      video
      subtitle
      contentHeader
      contentText
      categoriesOrder{
        name
        id
        slug
        posterPost{
          name
          mainImage{
            publicUrl
          }
          mainImageCrop
        }
      }
      mainImage{
        publicUrl
      }
      image01{
        publicUrl
      }
      image02{
        publicUrl
      }
      image03{
        publicUrl
      }
      image04{
        publicUrl
      }
      image05{
        publicUrl
      }
      image06{
        publicUrl
      }
    }
  }`;
}

const getPosts=(categoryID)=>{
  return gql`
  {
    allPosts(where: { categories_some: { id:"${categoryID}" } },sortBy: projectDate_DESC){
      id
      name
      slug
      projectDate
      mainImage{
        publicUrl
      }
      mainImageCrop
      categories{
        id
      }
    }
  }`;
}
const getPostsBySlug=(categorySlug)=>{
  return gql`
  {
    allPosts(where: { categories_some: { slug:"${categorySlug}" } },sortBy: projectDate_DESC){
      id
      name
      title
      slug
      projectDate
      mainImage{
        publicUrl
      }
      mainImageCrop
      categories{
        id
        slug
        name
      }
    }
  }`;
}
const getPost=(postID)=>{
  return gql`
  {
      Post(where: { id: "${postID}" }){
        id
        name
        title
        published
        projectDate
        categories{
          name
          slug
        }
        mainImage{
          publicUrl
        }
        mainImageCrop
        description
        more
        extra
        video
        videoAspectRatio
        urlLink
        image01{
          publicUrl
        }
        image02{
          publicUrl
        }
        image03{
          publicUrl
        }
        image04{
          publicUrl
        }
        image05{
          publicUrl
        }
        image06{
          publicUrl
        }
        image07{
          publicUrl
        }
        image08{
          publicUrl
        }
        image09{
          publicUrl
        }
        image10{
          publicUrl
        }
        image11{
          publicUrl
        }
        image12{
          publicUrl
        }
        image13{
          publicUrl
        }
        relatedPosts{
          name
          slug
          mainImage{
          	publicUrl
        	}
        }
      }
  }`;
}

const getPostBySlug=(postSlug)=>{
  return gql`
  {
      allPosts(where: { slug: "${postSlug}" }){
        name
        title
        published
        projectDate
        categories{
          name
          slug
        }
        mainImage{
          publicUrl
        }
        mainImageCrop
        description
        more
        extra
        video
        videoAspectRatio
        urlLink
        image01{
          publicUrl
        }
        image02{
          publicUrl
        }
        image03{
          publicUrl
        }
        image04{
          publicUrl
        }
        image05{
          publicUrl
        }
        image06{
          publicUrl
        }
        image07{
          publicUrl
        }
        image08{
          publicUrl
        }
        image09{
          publicUrl
        }
        image10{
          publicUrl
        }
        image11{
          publicUrl
        }
        image12{
          publicUrl
        }
        image13{
          publicUrl
        }
        relatedPosts{
          name
          slug
          mainImage{
            publicUrl
          }
        }
      }
  }`;
}

export {getCategories,getPosts,getPost,getPostBySlug,getPostsBySlug,getPageBySearch,getCategoryTopPostsBySlug,getCategoryTopPostsByID};
