import { Container,Grid } from "@material-ui/core";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import CardContainer from './CardContainer';

function PostImages({post}) {
  const[currentImage,setCurrentImage] = useState('nada');
  const[lightboxShow,setLightboxShow] = useState('hide');

  const handleImageClick = (e) => {
     e.preventDefault();
     const imageId=Number(e.target.dataset.id);
     const urlArr=imagesArr[imageId].image.publicUrl.split("upload");
     let imagesURL=urlArr[0]+"upload/f_auto,q_70,w_600,c_scale"+urlArr[1]+" 600w,";
     imagesURL+=urlArr[0]+"upload/f_auto,q_70,w_960,c_scale"+urlArr[1]+" 960w,";
     imagesURL+=urlArr[0]+"upload/f_auto,q_70,w_1280,c_scale"+urlArr[1]+" 1280w";
     const mainImage=<img  srcSet={imagesURL}
                     sizes="(max-width: 599px) 100vw,(max-width: 959px) 600px,(max-width: 1279px) 960px,(min-width: 1280px) 1280px"
                     src={urlArr[0]+"upload/f_auto,q_70,w_1280,c_scale"+urlArr[1]}
                     alt={post.name+" current image"}  onClick={(e) => e.stopPropagation()} />
     //setCurrentImage(<img alt={post.name+" current image"} src={image} width="100%" height="auto"  onClick={(e) => e.stopPropagation()}/>);
     setCurrentImage(mainImage);
     setLightboxShow('show');
  }

  const handleLightboxClick = (e) => { setLightboxShow('hide'); }

  let postImages,imagesArr=[];
  const allImagesSlotsArr=[post.image01,post.image02,post.image03,post.image04,post.image05,post.image06,post.image07,
                   post.image08,post.image09,post.image10,post.image11,post.image12,post.image13];

  for(var j=0;j<allImagesSlotsArr.length;j++){
    if(allImagesSlotsArr[j]!==null) {
      imagesArr.push({image:allImagesSlotsArr[j]});
    }else{
      break;
    }
  }

  const imagesAmount=imagesArr.length;
  if(imagesAmount>4){
    const foo=Math.floor(imagesAmount/5);
    const amountLeft=imagesAmount-foo*5;
    for(var i=0;i<imagesAmount;i++){
      if(i<foo*5){
        if(i%5<3)imagesArr[i].grid=4
        else imagesArr[i].grid=6;
      }else{
        if(amountLeft===2){
          imagesArr[i].grid=6;
        }else if(amountLeft===4){
          //imagesArr[i].grid=6;
          if(i%5<3)imagesArr[i].grid=4
          else imagesArr[i].grid=12;
        }else if(amountLeft===3){
          imagesArr[i].grid=4;
        }else if(amountLeft===1){
          imagesArr[i].grid=12;
        }
      }
    }
  }else if(imagesAmount===4 || imagesAmount===2){
    for(var k=0;k<imagesAmount;k++) imagesArr[k].grid=6;
  }else if(imagesAmount===3){
    for(var l=0;l<imagesAmount;l++) imagesArr[l].grid=4;
  }else if(imagesAmount===1){
    imagesArr[0].grid=12;
  }

  //for(var m=0;m<imagesAmount;m++) console.log("imagesArr:"+imagesArr[m].grid);


  if(imagesArr.length>0){
    postImages=<Container disableGutters={true} className="dark-grey-container grid-list-wrapper"  maxWidth={false}>
                  <Container  disableGutters={true} fixed>
                    <Grid container>
                      {
                        imagesArr.map((data,index) => {
                          //console.log("data.grid:"+data.grid);
                        let imagesURL,image;
                        const urlArr=data.image.publicUrl.split("upload");
                        if(data.grid===4){
                              imagesURL=urlArr[0]+"upload/f_auto,q_70,w_568,h_254,c_fill,g_north"+urlArr[1]+" 568w,";
                              imagesURL+=urlArr[0]+"upload/f_auto,q_70,w_268,h_150,c_fill,g_north"+urlArr[1]+" 268w,";
                              imagesURL+=urlArr[0]+"upload/f_auto,q_70,w_288,h_162,c_fill,g_north"+urlArr[1]+" 288w,";
                              imagesURL+=urlArr[0]+"upload/f_auto,q_70,w_395,h_223,c_fill,g_north"+urlArr[1]+" 395w";
                              image=<img  srcSet={imagesURL}
                                          sizes="(max-width: 599px) calc(100vw - 32px),(max-width: 959px) 268px,(max-width: 1279px) 288px,(min-width: 1280px) 395px"
                                          src={urlArr[0]+"upload/f_auto,q_70,w_395,h_223,c_fill,g_north"+urlArr[1]}
                                          alt={post.name+" image "+index} />
                        }else if(data.grid===6){
                              imagesURL=urlArr[0]+"upload/f_auto,q_70,w_568,h_254,c_fill,g_north"+urlArr[1]+" 568w,";
                              imagesURL+=urlArr[0]+"upload/f_auto,q_70,w_268,h_150,c_fill,g_north"+urlArr[1]+" 268w,";
                              imagesURL+=urlArr[0]+"upload/f_auto,q_70,w_448,h_252,c_fill,g_north"+urlArr[1]+" 448w,";
                              imagesURL+=urlArr[0]+"upload/f_auto,q_70,w_608,h_342,c_fill,g_north"+urlArr[1]+" 608w";
                              image=<img  srcSet={imagesURL}
                                          sizes="(max-width: 599px) calc(100vw - 32px),(max-width: 959px) 268px,(max-width: 1279px) 448px,(min-width: 1280px) 608px"
                                          src={urlArr[0]+"upload/f_auto,q_70,w_608,h_342,c_fill,g_north"+urlArr[1]}
                                          alt={post.name+" image "+index} />
                        }else if(data.grid===12){
                              //const urlArr=["https://res.cloudinary.com/eeeps/image/","/on_the_phone.jpg"];
                              imagesURL=urlArr[0]+"upload/f_auto,q_70,w_568,h_254,c_fill,g_north"+urlArr[1]+" 568w,";
                              imagesURL+=urlArr[0]+"upload/f_auto,q_70,w_268,h_150,c_fill,g_north"+urlArr[1]+" 268w,";
                              imagesURL+=urlArr[0]+"upload/f_auto,q_70,w_928,h_252,c_fill,g_north"+urlArr[1]+" 928w,";
                              imagesURL+=urlArr[0]+"upload/f_auto,q_70,w_1248,h_342,c_fill,g_north"+urlArr[1]+" 1248w,";
                              image=<img  srcSet={imagesURL}
                                              sizes="(max-width: 599px) calc(100vw - 32px),(max-width: 959px) 268px,(max-width: 1279px) 928px,(min-width: 1280px) 1248px"
                                              src={urlArr[0]+"upload/f_auto,q_70,w_1248,h_342,c_fill,g_north"+urlArr[1]}
                                              alt={post.name+" image "+index} />
                        }

                        return (
                          <Grid item xs={12} sm={6} md={data.grid} key={index}>
                            <Link className="card-anchor" data-id={index} onClick={(e) => handleImageClick(e)} to={'/'}>
                              <CardContainer image={ image} />
                            </Link>
                          </Grid>
                        )
                        })
                      }
                    </Grid>
                  </Container>
                  <div className={'lightbox-wrapper '+lightboxShow} onClick={(e) => handleLightboxClick(e)}>
                    <Container disableGutters={true} className="lightbox-container" fixed>
                      <div className="lightbox-exit-container"><HighlightOffIcon fontSize="inherit" /></div>
                      {currentImage}
                    </Container>
                  </div>
                </Container>
  }else{
    return '';
  }

  return (
    <section className="post-images">
    {postImages}
    </section>);
  }

export default PostImages;
