import { Container,Grid,Box } from "@material-ui/core";
import {  useTheme } from '@material-ui/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

function PageParagraphImages({page}) {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'), { defaultMatches: true });

  let postImages,imagesArr=[];
  const allImagesSlotsArr=[page.image01,page.image02,page.image03,page.image04,page.image05,page.image06];

  for(var j=0;j<allImagesSlotsArr.length;j++){
    if(allImagesSlotsArr[j]!==null) {
      imagesArr.push({image:allImagesSlotsArr[j]});
    }else{
      break;
    }
  }

  if(imagesArr.length>0){
    postImages=<Container disableGutters={true} className="page-paragraph-images-wrapper" maxWidth={false}>
                  <Grid container>
                      {
                        imagesArr.map((data,index) => {
                        //const isLast=(index===imagesArr.length-1);

                        let imagesURL,image;
                        const urlArr=data.image.publicUrl.split("upload");

                        imagesURL=urlArr[0]+"upload/f_auto,q_70,w_568,h_254,c_fill,g_center"+urlArr[1]+" 568w,";
                        imagesURL+=urlArr[0]+"upload/f_auto,q_70,w_260,h_146,c_fill,g_center"+urlArr[1]+" 260w,";
                        imagesURL+=urlArr[0]+"upload/f_auto,q_70,w_288,h_162,c_fill,g_center"+urlArr[1]+" 288w,";
                        imagesURL+=urlArr[0]+"upload/f_auto,q_70,w_395,h_223,c_fill,g_center"+urlArr[1]+" 395w";
                        image=<img  srcSet={imagesURL}
                                    sizes="(max-width: 599px) calc(100vw - 32px),(max-width: 959px) 260px,(max-width: 1279px) 288px,(min-width: 1280px) 395px"
                                    src={urlArr[0]+"upload/f_auto,q_70,w_395,h_223,c_fill,g_center"+urlArr[1]}
                                    alt={page.name+" image "+index} />


                        return (
                          <Grid item xs={12} sm={6} md={4} key={index} className="page-paragraph-image-grid-item">
                            <Box mx={isMobile ? 0 : 1} mb={2} className='page-paragraph-image-container'>{image}</Box>
                          </Grid>
                        )
                        })
                      }
                    </Grid>
                </Container>
  }else{
    return '';
  }
  return (postImages)
}

export default PageParagraphImages;
